var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "icons", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Icons", link: "components/icons" }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "base-material-card",
                {
                  attrs: { color: "green" },
                  scopedSlots: _vm._u([
                    {
                      key: "heading",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "display-2 font-weight-light" },
                            [
                              _vm._v(
                                "\n            Material Design Icons\n          "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "subtitle-1 font-weight-light" },
                            [
                              _vm._v(
                                "\n            See all available\n            "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    href: "https://materialdesignicons.com/",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("\n              Icons\n            ")]
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    _vm._l(_vm.icons, function(icon) {
                      return _c(
                        "v-col",
                        { key: icon, staticClass: "ma-2" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { "content-class": "top", top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var attrs = ref.attrs
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b({}, "v-icon", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(icon) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(icon))])]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    href: "https://materialdesignicons.com/",
                    large: "",
                    target: "_blank"
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("\n          mdi-material-design\n        ")
                  ]),
                  _c("span", [_vm._v("See all icons")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }